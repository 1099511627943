/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React, { useContext } from "react";
import {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonCardSubtitle,
    IonLabel
} from "@ionic/react";
import TimeAgo from "react-timeago";
// @ts-ignore
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
// @ts-ignore
import norwegianStrings from "react-timeago/lib/language-strings/no";

import { useLocation } from "react-router";
import { AppContext } from "store";
import { INotification, ContentType } from "store/types";
import RefreshingPage from "pages/RefreshingPage";
import { stringToConsistentDateForAndroidIos } from "utility/dateLogic";

export const NotificationsPage: React.FC = () => {
    const { pathname, hash } = useLocation();
    const context = useContext(AppContext);
    const { notificationAlert, setNotificationAlert, notifications } = context;

    if (notificationAlert && pathname === "/varslinger") {
        setNotificationAlert(false);
    }

    if (hash.includes("#")) {
        const conferenceIdOfNotification = hash.replace("#", "");

        if (conferenceIdOfNotification && conferenceIdOfNotification.length === 36 && context.selectedConferenceId !== conferenceIdOfNotification) {
            context.setSelectedConferenceId(conferenceIdOfNotification);
        }
    }

    let sortedNotifications: INotification[] = [];

    if (notifications.length) {
        sortedNotifications = notifications.sort(
            (a: INotification, b: INotification) => {
                const aDate = +new Date(a.scheduledTime);

                // See https://github.com/microsoft/TypeScript/issues/5710
                return +new Date(b.scheduledTime) - aDate;
            }
        );
    }

    const formatter = buildFormatter(norwegianStrings);

    const notificationCards = sortedNotifications.map(
        ({ id, title, body, scheduledTime, conferenceName }: INotification) => (
            <IonCard key={id} className="card-background-color notification-card" >
                <IonCardHeader>
                    <IonCardTitle>{title}</IonCardTitle>
                    <IonCardSubtitle>
                        <TimeAgo date={stringToConsistentDateForAndroidIos(scheduledTime)} formatter={formatter} />
                        {conferenceName && <IonLabel> i {conferenceName}</IonLabel>}
                    </IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent>{body}</IonCardContent>
            </IonCard>
        )
    );

    return (
        <RefreshingPage
            pageContent={notificationCards}
            itemCount={notifications.length}
            contentType={ContentType.Notifications}
        />
    );
};
