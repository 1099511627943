import React, { useContext } from "react";
import { IonButton, IonIcon, IonChip, IonRow } from "@ionic/react";
import { heart, heartEmpty } from "ionicons/icons";

import { timeStringAsHHMM, getDateStringWithDay } from "utility/dateLogic";
import { ISpeakerLecture } from "store/types";
import { AppContext } from "store";
import { pinOutline } from "icons";

interface IProps {
    lectures: ISpeakerLecture[];
}

const SpeakerLectures: React.FC<{ lectures: ISpeakerLecture[]; }> = ({ lectures }: IProps): JSX.Element => {
    const context = useContext(AppContext);

    const onHeartToggle = (id: number, event: MouseEvent): void => {
        event.stopPropagation();
        context.setFavorite(id, !context.isFavorite(id));
    };

    const days = lectures.map((lecture: ISpeakerLecture) => {
        const date = new Date(lecture.startTime);
        const lectureDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());

        return lectureDate.valueOf();
    });

    const uniqueDays = days.filter((v: number, i: number) => days.indexOf(v) === i);

    return (
        <>
            {uniqueDays.map((day: number) => (
                <div key={day}>
                    <div key={day}
                        className="ion-item-divider semi-bold title ion-header ion-padding-bottom md hydrated"
                        style={{ color: "#262626", textAlign: "center" }}
                    >
                        {getDateStringWithDay(new Date(day))}
                    </div>

                    {
                        lectures.filter((lecture: ISpeakerLecture) => new Date(lecture.startTime).getDate() === new Date(day).getDate())
                            .map((lecture: ISpeakerLecture) => (
                                <div key={lecture.id}>
                                    <div className="time-seperator speaker-time-separator ion-margin-top">
                                        <span>
                                            {timeStringAsHHMM(lecture.startTime)} - {timeStringAsHHMM(lecture.endTime)}
                                        </span>
                                    </div>
                                    <span
                                        className="card-space-between"
                                        style={{ display: "flex", alignItems: "center" }}
                                    >
                                        <b>{lecture.name}</b>
                                        <IonButton
                                            fill="clear"
                                            onClick={(event: any): void => onHeartToggle(lecture.id, event)}
                                            className="ion-button-icon"
                                        >
                                            <IonIcon
                                                className="favorite-icon"
                                                slot="icon-only"
                                                icon={context.isFavorite(lecture.id) ? heart : heartEmpty}
                                            />
                                        </IonButton>
                                    </span>
                                    <p>{lecture.description}</p>
                                    <IonRow className="" style={{ marginTop: 5, justifyContent: "flex-start", display: "flex" }}>
                                        <IonChip
                                            className="location-chip"
                                            color="white"
                                        >
                                            <IonIcon icon={pinOutline} />
                                            <p className="subtitle">{lecture.location}</p>
                                        </IonChip>
                                    </IonRow>
                                    {lecture.tags && <IonRow className="ion-padding-bottom" style={{ display: "flex", flexWrap: "wrap" }}>
                                        {lecture.tags.map((tag: string) => (
                                            <span key={tag} className="tag ">
                                                {tag}
                                            </span>
                                        ))}
                                    </IonRow>}

                                </div>
                            ))
                    }
                </div>
            ))}
        </>
    );
};

export default SpeakerLectures;
