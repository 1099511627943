import axios, { AxiosRequestConfig } from "axios";
import { authContext, getToken } from "../config/adal.config";
import { getBouvetEventEnvironmentEnum, getBackendApiUrl } from "../config/environment";
import { IFavoritesData, INotification, ErrorMessage, IEvent, IRawData } from "store/types";

const backendClient = axios.create({
    baseURL: getBackendApiUrl(),
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
    },
    timeout: 5000
});

backendClient.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
        config.headers.Authorization = `Bearer ${getToken()}`;

        return config;
    },
    (error: Error) => {
        Promise.reject(error);
    }
);

export const getUserNotifications = async (
    conferences: IEvent[]
): Promise<INotification[]> => {
    const userId = authContext.getCachedUser().profile.oid;

    try {
        let notifications: INotification[] = [];

        for await (const conference of conferences) {
            const result = await backendClient.get(
                `Notifications/for-conference/${getBouvetEventEnvironmentEnum()}/${conference.id}/${userId}`
            );

            result.data.forEach((notification: INotification) => {
                notification.conferenceName = conference.eventName;
            });
            notifications = notifications.concat(result.data);
        }

        return notifications;
    } catch {
        throw new Error(ErrorMessage.Notifications);
    }
};

export const getConferenceJson = async (conferenceId: string): Promise<IRawData> => {
    const params = {
        filename: `${conferenceId}.json`
    };

    const result = await backendClient.get("Json", { params });

    return JSON.parse(result.data);
};

export const getUserFavorites = async (
    conferenceId: string
): Promise<IFavoritesData> => {
    const userId = authContext.getCachedUser().profile.oid;

    try {
        const result = await backendClient.get(
            `Favorites/${userId}/${conferenceId}`
        );

        return result.data;
    } catch {
        throw new Error(ErrorMessage.Favorites);

    }
};

export const addFavorite = async (
    conferenceId: string,
    lectureId: string
): Promise<string> => {
    const userId = authContext.getCachedUser().profile.oid;

    try {
        const result = await backendClient.post(
            `Favorites/${userId}/${conferenceId}/${lectureId}`
        );

        return result.data;
    } catch {
        throw new Error(ErrorMessage.SaveFavorite);
    }
};

export const deleteFavorite = async (
    conferenceId: string,
    lectureId: string
): Promise<string> => {
    const userId = authContext.getCachedUser().profile.oid;

    try {
        const result = await backendClient.delete(
            `Favorites/${userId}/${conferenceId}/${lectureId}`
        );

        return result.data;
    } catch {
        throw new Error(ErrorMessage.DeleteFavorite);
    }
};

export const getThemePictureInBase64 = async (conferenceId: string): Promise<string> => {
    const result = await backendClient.get(`/ThemePicture/base64/${conferenceId}`);

    return result.data;
};

export default { getUserNotifications, getUserFavorites, addFavorite, deleteFavorite, getThemePictureInBase64, getConferenceJson };
