import React from "react";
import { IonSearchbar } from "@ionic/react";

interface IProps {
    onSearchBarChange: (newSearch: string) => void;
}

export const LectureSearchBar = ({ onSearchBarChange }: IProps): JSX.Element => (
    <div className="search">
        <IonSearchbar
            placeholder="Søk"
            mode="ios"
            debounce={0}
            onIonChange={(event: CustomEvent): void => onSearchBarChange((event.target as HTMLInputElement).value)}
        />
    </div>
);

