import React, { useContext } from "react";
import { AppContext } from "store";
import { ILecture, ITimeSlot, ContentType } from "store/types";
import { TimeSlot, DayHeader } from "components";
import { getUniqueDays, isTimeSlotOnDate } from "utility/dateLogic";
import RefreshingPage from "pages/RefreshingPage";

export const FavoritesPage: React.FC = () => {
    const { timeSlots, isFavorite } = useContext(AppContext);

    const uniqueDays = getUniqueDays(timeSlots.map(([, timeSlot]: [string, ITimeSlot]) => timeSlot));

    const timeSlotsForDayList = uniqueDays.map((day: number) => (
        <div key={day}>
            <DayHeader day={day} />
            {
                timeSlots
                    .filter(
                        ([, timeSlot]: [string, ITimeSlot]) => isTimeSlotOnDate(timeSlot, new Date(day))
                    )
                    .map(([key, timeSlot]: [string, ITimeSlot]) => {
                        const favoriteLectures = timeSlot.lectures
                            .filter((lecture: ILecture) => isFavorite(lecture.id));

                        return (
                            <TimeSlot
                                key={key}
                                startTime={timeSlot.startTime}
                                endTime={timeSlot.endTime}
                                lectures={favoriteLectures}
                            />
                        );
                    })
            }
        </div>
    ));

    return (
        <RefreshingPage
            pageContent={timeSlotsForDayList}
            itemCount={timeSlotsForDayList.length}
            contentType={ContentType.Agenda}
        />
    );
};
