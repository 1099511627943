import React, { useContext, useState, createRef } from "react";
import {
    IonRefresher,
    IonContent,
    IonRefresherContent,
    IonToast
} from "@ionic/react";
import { RefresherEventDetail } from "@ionic/core";
import { AppContext } from "store";
import { NoContent, MissingContent } from "components";
import CardLoader from "components/CardLoader";
import { ContentType } from "store/types";

interface IProps {
    contentType: ContentType;
    pageContent: JSX.Element | JSX.Element[];
    itemCount: number;
}

interface IScrollDetail {
    scrollTop: number;
}

export const RefreshingPage: React.FC<IProps> = ({ pageContent, itemCount, contentType }: IProps) => {
    const context = useContext(AppContext);
    const refresherReference = createRef<HTMLIonRefresherElement>();
    const [scrollY, setScrollY] = useState(0);

    const didContentTypeFailToLoad = (): boolean => {
        if (contentType === ContentType.Conferences) {
            return context.failedToLoadEventData;
        } else if (contentType === ContentType.Notifications) {
            return context.failedToLoadNotifications;
        } else {
            return context.failedToLoadJson;
        }
    };

    const handleRefresh = async (event: CustomEvent<RefresherEventDetail>
    ): Promise<void> => {
        const startRefresh = +new Date();

        await context.fetchData(true);

        let timeDifference = +new Date() - startRefresh;

        timeDifference /= 1000;

        if (timeDifference < 500 && !didContentTypeFailToLoad()) {
            setTimeout(() => {
                event.detail.complete();
            }, 500 - timeDifference);
        } else {
            event.detail.complete();
        }

    };

    const getContentToDisplay = (): JSX.Element | JSX.Element[] => {
        if (context.isLoading) {
            return <CardLoader />;
        } else if (itemCount > 0) {
            return pageContent;
        } else if (didContentTypeFailToLoad()) {
            return <NoContent />;
        } else {
            return <MissingContent contentType={contentType} />;
        }
    };

    return (
        <>
            <IonRefresher
                ref={refresherReference}
                onIonRefresh={handleRefresh}
                style={{ marginTop: 12 }}
                slot="fixed"
                pullMin={75}
                disabled={scrollY > 15}
            >
                <IonRefresherContent refreshingSpinner="crescent" pullingIcon="nothing" />
            </IonRefresher>
            <IonContent scrollEvents onIonScroll={(event: CustomEvent<IScrollDetail>): void => setScrollY(event.detail.scrollTop)}>
                {getContentToDisplay()}
            </IonContent>
            <IonToast
                mode="md"
                isOpen={context.error !== ""}
                position="top"
                onDidDismiss={(): void => context.setError("")}
                message={context.error}
                duration={500}
            />

        </>
    );
};

export default RefreshingPage;

