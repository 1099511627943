import {
    Plugins,
    PushNotification,
    PushNotificationActionPerformed,
    PushNotificationToken
} from "@capacitor/core";
import { FCM } from "capacitor-fcm";
import { authContext } from "../config/adal.config";
import { isApp } from "../config/environment";

const initNotifications = async (): Promise<void> => {
    if (isApp) {
        const fcm = new FCM();
        const { PushNotifications } = Plugins;

        PushNotifications.removeAllDeliveredNotifications();

        const userId = authContext.getCachedUser().profile.oid;

        PushNotifications.addListener(
            "registration",
            (token: PushNotificationToken) => {
                console.log(`Push registration success, token: ${token.value}`);
            }
        );

        PushNotifications.addListener("registrationError", (error: Error) => {
            console.error("Error registering PushNotifications:", error);
        });

        await PushNotifications.register();

        fcm.subscribeTo({ topic: userId });

        PushNotifications.addListener(
            "pushNotificationReceived",
            (notification: PushNotification) => {
                window.location.pathname = "/varslinger";
            }
        );

        PushNotifications.addListener(
            "pushNotificationActionPerformed",
            (notification: PushNotificationActionPerformed) => {
                window.location.pathname = `/varslinger#${notification.notification.data.conferenceId}`;
            }
        );
    }
};

export default {
    initNotifications
};
