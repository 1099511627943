import React from "react";
import { Plugins } from "@capacitor/core";
import { IonApp, IonSplitPane } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { ApolloProvider } from "@apollo/react-hooks";

import StateProvider from "./store";
import { Tabs } from "./components";
import { bouvetEventClient } from "./services";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";

/* Theme variables */
import "./styles/variables.css";
import "./styles/theme.css";

const { SplashScreen } = Plugins;

const App: React.FC = () => {
    SplashScreen.hide();

    return (
        <IonApp style={{ background: "#f8f8f8" }}>
            <ApolloProvider client={bouvetEventClient}>
                <IonReactRouter>
                    <IonSplitPane contentId="main" className="split-pane">
                        <StateProvider>
                            <Tabs />
                        </StateProvider>
                    </IonSplitPane>
                </IonReactRouter>
            </ApolloProvider>
        </IonApp>
    );
};

export default App;
