import { IonCard, IonItemDivider, IonItemGroup, IonSpinner } from "@ionic/react";
import React from "react";
import ContentLoader from "react-content-loader";

const cardsLoading = (): JSX.Element[] => Array.from({ length: 10 }, (_: any, i: number) => (
    <IonCard key={i} className="lecture-card card-background-color">
        <ContentLoader
            height={160}
            width={400}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
            baseUrl={window.location.pathname}
        >
            <rect x="6" y="19" rx="4" ry="4" width="249" height="9" />
            <rect x="6" y="55" rx="3" ry="3" width="80" height="6" />
            <rect x="6" y="114" rx="3" ry="3" width="201" height="16" />
            <rect x="6" y="37" rx="4" ry="4" width="205" height="6" />
            <rect x="288" y="114" rx="3" ry="3" width="100" height="16" />
            <circle cx="264" cy="122" r="16" />
        </ContentLoader>
    </IonCard>
));

const CardLoader = (): JSX.Element => (
    <>
        <IonItemDivider key="loaderDivider" sticky mode="md">
            <IonSpinner style={{ display: "block", margin: "0 auto" }} />
        </IonItemDivider>
        <IonItemGroup key="loaderGroup" className="card-list">
            {cardsLoading()}
        </IonItemGroup>
    </>
);

export default CardLoader;
