import { isPlatform } from "@ionic/react";
import config from "./environment.config.json";

// Set this to false if you want to connect to Bouvet Events' Dev API , false if you want to connect with BouvetEvent running locally
const USE_BOUVET_EVENT_LOCALHOST = false;

// Set this to false if you want to connect to your backend running locally
const USE_BACKEND_IN_PRODUCTION = true;

const { environment } = config;

export const isApp =
    process.env.NODE_ENV !== "test" &&
    isPlatform("capacitor") &&
    !document.URL.startsWith("https");

const isDevelopmentStaticWebsiteInstance = environment === "development";

const isProductionStaticWebsiteInsance = environment === "production";

enum BouvetEventEnvironment {
    Production = 0,
    Development = 1,
    Local = 2
}

// By default queries will go to the equivalent of the currently runnin environment
export const getBouvetEventEnvironmentEnum = (): number => {
    if (isApp || isProductionStaticWebsiteInsance) {
        return BouvetEventEnvironment.Production;
    } else if (isDevelopmentStaticWebsiteInstance || !USE_BOUVET_EVENT_LOCALHOST) {
        return BouvetEventEnvironment.Development;
    } else {
        return BouvetEventEnvironment.Local;
    }
};

const bouvetEventEnvironment: BouvetEventEnvironment = getBouvetEventEnvironmentEnum();

export const getBouvetEventApiUrl = (): string => {
    switch (bouvetEventEnvironment) {
        case BouvetEventEnvironment.Production:
            return "https://event.bouvet.no/graphql";
        case BouvetEventEnvironment.Development:
            return "https://event.dev.bouvetapps.io/graphql";
        default:
            return "https://localhost:56058/graphql";
    }
};

export const getBackendApiUrl = (): string => {
    if (USE_BACKEND_IN_PRODUCTION) {
        return "https://bouvetcon.azurewebsites.net/api/";
    } else {
        return "https://localhost:5001/api/";
    }
};
