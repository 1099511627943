import React from "react";
import { IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonIcon, IonLabel, IonPage } from "@ionic/react";
import { Route, Redirect } from "react-router-dom";

import { pages } from "../pages";
import { IPage } from "../store/types";
import { Menu, Page } from ".";

const indexRedirect = (): JSX.Element => <Redirect to="/hjem" />;

export const Tabs: React.FC = () => (
    <>
        <Menu pages={pages} />
        <IonPage id="main">
            <IonTabs>
                <IonRouterOutlet>
                    {pages.map(({ tab, title, component }: IPage) => (
                        <Route key={tab} exact path={`/:tab(${tab})`}>
                            <Page title={title} PageComponent={component} />
                        </Route>
                    ))}
                    <Route
                        path="/"
                        render={indexRedirect}
                        exact
                    />
                    <Route
                        path="/null"
                        render={indexRedirect}
                        exact
                    />
                    <Route
                        path="/signin-oidc"
                        render={indexRedirect}
                        exact
                    />
                </IonRouterOutlet>
                <IonTabBar slot="bottom" >
                    {pages.map(
                        ({ tab, icon, title }: IPage) => (
                            <IonTabButton
                                style={{ display: tab === "varslinger" ? "none" : "hidden" }}
                                key={tab}
                                tab={tab}
                                href={`/${tab}`}
                            >
                                <IonIcon icon={icon} />
                                <IonLabel>{title}</IonLabel>
                            </IonTabButton>
                        )
                    )}
                </IonTabBar>
            </IonTabs>

        </IonPage>
    </>
);
