import { ISpeaker, ILecture, ILectureSpeaker } from "store/types";

export const satisfiesSearch = (fieldsToSearch: string[], search: string): boolean => {
    if (!search) {
        return true;
    }

    search = search.toLowerCase();

    return fieldsToSearch.some((match: string) => match.toLowerCase().includes(search));
};

export const getSpeakerSearchFields = (speaker: ISpeaker): string[] => {
    if (speaker.division) {
        return [speaker.division, speaker.name];
    }

    return [speaker.name];
};

export const getLectureSearchFields = (lecture: ILecture): string[] => {
    let array = [lecture.name, lecture.description, lecture.category];

    array = array.concat(lecture.tags);
    array = array.concat(lecture.speakers.map((speaker: ILectureSpeaker) => speaker.name));

    return array;
};
