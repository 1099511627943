import { Plugins } from "@capacitor/core";

import { IEvent } from "../store/types";
import { GET_CONFERENCES, GET_CONFERENCE, GET_USER_PHOTOS, RESOLVE_UNIT_NAME } from "./queries";

import { bouvetEventClient } from ".";

const { Storage } = Plugins;

const getConferences = async (): Promise<IEvent[]> => {
    const { data } = await bouvetEventClient.query({ query: GET_CONFERENCES });

    return data.invitedEvents.filter((event: IEvent) => event.modules.length).reverse();
};

const getConferenceByEventId = async (eventId: string): Promise<IEvent> => {
    const { data } = await bouvetEventClient.query({
        query: GET_CONFERENCE,
        variables: { eventId },
        fetchPolicy: "network-only"
    });

    return data.event;
};

const getSpeakerImages = async (users: string[]): Promise<Map<string, string>> => {
    const { data } = await bouvetEventClient.query({
        query: GET_USER_PHOTOS,
        variables: { users }
    });

    return new Map(data.photos.map(({ id, photo }: { id: string; photo: string; }) => [id, photo]));
};
const resolveUnitName = async (userId: string[]): Promise<Map<string, string>> => {
    const { data } = await bouvetEventClient.query({
        query: RESOLVE_UNIT_NAME,
        variables: { userId }
    });

    return new Map(data.resolveUnitNameList.map(({ userId, unit }: { userId: string; unit: string; }) => [userId, unit]));
};

const getLastConferenceId = async (): Promise<string> => {
    const { value } = await Storage.get({ key: "lastConferenceId" });

    return value ? value : "";
};

const setLastConferenceId = async (conferenceId: string): Promise<void> => {
    Storage.set({
        key: "lastConferenceId",
        value: conferenceId
    });
};

export default {
    getConferences,
    getConferenceByEventId,
    getSpeakerImages,
    resolveUnitName,
    getLastConferenceId,
    setLastConferenceId
};
