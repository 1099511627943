import React, { useContext, useState, useEffect } from "react";
import {
    IonImg
} from "@ionic/react";
import { AppContext } from "store";
import RefreshingPage from "pages/RefreshingPage";
import { getThemePictureInBase64 } from "services/backendService";
import { ContentType } from "store/types";

export const HomePage: React.FC = () => {
    const context = useContext(AppContext);
    const conferenceId = context.selectedConferenceId;
    const { conferences, name, description } = context;
    const [themePictureInBase64, setThemePictureInBase64] = useState("");

    useEffect(() => {
        if (conferenceId.length > 0) {
            getThemePictureInBase64(conferenceId).then((base64String: string) => {
                setThemePictureInBase64(base64String);
            });
        }
    }, [conferenceId]);

    const getThemePicture = (): JSX.Element => {
        if (themePictureInBase64.length > 0) {
            return (
                <>
                    <IonImg
                        className="img-homepage-desktop"
                        alt="BouvetOne Banner"
                        src={`data:image/jpeg;base64,${themePictureInBase64}`}
                    />
                    <IonImg
                        className="img-homepage-phone"
                        alt="BouvetOne Banner"
                        src={`data:image/jpeg;base64,${themePictureInBase64}`}
                    />
                </>
            );
        }

        return <></>;
    };

    const eventDescription = (
        <>
            {getThemePicture()}
            <div style={{ padding: "0 13px" }}>
                <h1>{name}</h1>
                <div
                    className="content"
                    dangerouslySetInnerHTML={{ __html: description }}
                />
            </div>
        </>
    );

    return (
        <RefreshingPage
            pageContent={eventDescription}
            itemCount={conferences.length}
            contentType={ContentType.Conferences}
        />
    );

};
