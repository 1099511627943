import React, { useCallback, useContext, useState, useEffect } from "react";
import { IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonChip, IonIcon, IonRow, IonSpinner } from "@ionic/react";

import { AppContext } from "../store";
import pinOutline from "../icons/pin";
import { getDateStringWithDayAndTime } from "utility/dateLogic";

interface IConferenceProps {
    isSelected: boolean;
    conferenceName: string;
    description: string;
    id: string;
    location: string;
    startTimeString: string;
}

const ConferenceCard: React.FC<IConferenceProps> = ({
    isSelected,
    conferenceName,
    id,
    location,
    startTimeString

}: IConferenceProps) => {
    const context = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isSelected) {
            setIsLoading(false);
        }
    }, [isSelected]);

    const handleCardClick = useCallback(() => {
        if (!isSelected) {
            context.setSelectedConferenceId(id);
            setIsLoading(true);
        }
    }, [context, id, isSelected]);

    const getLocationLabel = (): string => {
        let { label } = JSON.parse(location);

        label = label.trim();

        if (label.endsWith(",")) {

            return label.slice(0, - 1);
        }

        return label;
    };

    return (
        <IonCard
            className={`lecture-card ${isSelected ? "primary" : "card-background-color"}`}
            style={{
                backgroundColor: isSelected ? "#deeff5" : "#FFFFFF"
            }}
            onClick={handleCardClick}

        >
            <IonRow className="card-space-between">
                <IonCardHeader className="ion-header">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <IonCardTitle className="semi-bold title ion-header">
                            {conferenceName}
                        </IonCardTitle>
                    </div>
                    <IonCardSubtitle className=" subtitle no-transform">
                        {getDateStringWithDayAndTime(new Date(startTimeString))}

                    </IonCardSubtitle>
                </IonCardHeader>
            </IonRow>
            {isLoading && <div className="spinner-center">
                <IonSpinner />
            </div>}

            {location && (
                <IonRow className="card-space-between card-padding">
                    <IonChip
                        className="ion-padding-top location-chip"
                        color="white"
                        mode="ios"
                        style={{ margin: 0 }}
                    >
                        <IonIcon icon={pinOutline} />
                        <p className="subtitle">{getLocationLabel()}</p>
                    </IonChip>
                </IonRow>)}
        </IonCard>
    );
};

export default ConferenceCard;
