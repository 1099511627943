import { Plugins } from "@capacitor/core";
import { format } from "date-fns";
import { ISpeaker, ITimeSlot, ITimeSlotLecture, IRawData, IEvent, IRoom } from "./types";
import { conferenceService } from "services/";

const { Storage } = Plugins;

export const parseConferenceJson = (
    data: IRawData
): {
    speakers: [string, ISpeaker][];
    timeSlots: [string, ITimeSlot][];
} => {
    const speakers = new Map<string, ISpeaker>();
    const timeSlots = new Map<string, ITimeSlot>();

    for (const lecture of data.lectures) {
        for (const timeSlot of lecture.timeSlots) {
            if (!timeSlot.room) {
                continue;
            }

            const startTime = format(new Date(timeSlot.startTime).setDate(new Date(timeSlot.startTime).getDate() - data.rooms.map((room: IRoom) => room.name).indexOf(timeSlot.room.name)), "yyyy-MM-dd'T'HH:mmxxx");
            const endTime = format(new Date(timeSlot.endTime).setDate(new Date(timeSlot.endTime).getDate() - data.rooms.map((room: IRoom) => room.name).indexOf(timeSlot.room.name)), "yyyy-MM-dd'T'HH:mmxxx");
            const startEndTime = startTime + endTime;
            const _timeSlot: ITimeSlot = timeSlots.has(startEndTime)
                ? (timeSlots.get(startEndTime) as ITimeSlot)
                : {
                    endTime,
                    lectures: [],
                    startTime
                };

            _timeSlot.lectures.push({
                category: lecture.category,
                description: lecture.description,
                id: timeSlot.id,
                speakers: lecture.lecturers,
                location: timeSlot.room.name,
                name: lecture.name,
                tags: lecture.tags
            });

            timeSlots.set(startEndTime, _timeSlot);

            timeSlots.forEach(
                (ts: ITimeSlot) => (ts.lectures = ts.lectures.sort(
                    (l1: ITimeSlotLecture, l2: ITimeSlotLecture) => l1.location < l2.location ? -1 : 1
                ))
            );

            for (const speaker of lecture.lecturers) {
                const _speaker: ISpeaker = speakers.has(speaker.name)
                    ? (speakers.get(speaker.name) as ISpeaker)
                    : {
                        ...speaker,
                        lectures: [],
                        division: "",
                        image: ""
                    };

                _speaker.lectures.push({
                    category: lecture.category,
                    description: lecture.description,
                    endTime,
                    id: timeSlot.id,
                    location: timeSlot.room.name,
                    name: lecture.name,
                    startTime,
                    tags: lecture.tags
                });

                speakers.set(speaker.name, _speaker);
            }
        }
    }

    const sortedTimeSlots = [...timeSlots.entries()].sort(([, t1]: [string, ITimeSlot], [, t2]: [string, ITimeSlot]) => t1.startTime < t2.startTime ? -1 : 1);

    const sortedSpeakers = [...speakers.entries()].sort(([, l1]: [string, ISpeaker], [, l2]: [string, ISpeaker]) => l1.name < l2.name ? -1 : 1);

    return {
        speakers: sortedSpeakers,
        timeSlots: sortedTimeSlots
    };
};

export const setNewestNotifications = async (
    count: number
): Promise<boolean> => {
    const { value } = await Storage.get({ key: "notificationCount" });
    let notificationAlert = false;

    if (value) {
        if (count > parseInt(value, 10)) {
            notificationAlert = true;

            Storage.set({
                key: "notificationCount",
                value: `${count}`
            });
        }
    } else {
        Storage.set({
            key: "notificationCount",
            value: "0"
        });
    }

    return notificationAlert;
};

// todo handle archived events
export const getDefaultConferenceId = async (conferences: IEvent[]): Promise<string> => {
    const defaultId: string = await conferenceService.getLastConferenceId();

    if (conferences.some((conference: IEvent) => conference.id === defaultId)) {
        return defaultId;
    }

    return conferences.length ? conferences[0].id : "";

};

