import React from "react";
import { IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonMenu, IonTitle, IonToolbar } from "@ionic/react";
import { NavLink } from "react-router-dom";

import { IPage } from "../store/types";

export const Menu: React.FC<{ pages: IPage[]; }> = ({ pages }: { pages: IPage[]; }) => (
    <IonMenu
        side="start"
        contentId="main"
        swipeGesture={false}
    >
        <IonHeader>
            <IonToolbar>
                <IonTitle>BouvetOne</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent>
            <IonList lines="none" className="menu-list">
                {pages.filter((page: IPage) => page.icon).map(({ tab, icon, title }: IPage) => (
                    <NavLink key={tab} to={`/${tab}`} activeClassName="selected" style={{ textDecoration: "none" }}>
                        <IonItem>
                            <IonIcon
                                icon={icon}
                                className="menu-icon-size"
                                slot="start"
                            />
                            <IonLabel className="menu-item-text">{title}</IonLabel>
                        </IonItem>
                    </NavLink>
                ))}
            </IonList>
        </IonContent>
    </IonMenu>
);

