import React from "react";

import { getDateStringWithDay } from "utility/dateLogic";

export const DayHeader = ({ day }: { day: number; }): JSX.Element => (
    <div
        key={day}
        className="ion-item-divider semi-bold title ion-header ion-padding md hydrated"
        style={{ color: "#262626", textAlign: "center" }}
    >
        {getDateStringWithDay(new Date(day))}
    </div>
);
