import React, { useContext, useState } from "react";
import { AppContext } from "../../store";
import { LectureSearchBar } from "components/LectureSearchBar";
import { TimeSlot, DayHeader } from "components";
import { ILecture, ITimeSlot, ContentType } from "store/types";
import { getLectureSearchFields, satisfiesSearch } from "utility/searchLogic";
import { getUniqueDays, isTimeSlotOnDate } from "utility/dateLogic";
import RefreshingPage from "pages/RefreshingPage";

const filterBySearch = (
    timeSlots: [string, ITimeSlot][], search: string
): [string, ITimeSlot][] => timeSlots
    .map(([key, timeSlot]: [string, ITimeSlot]): [
        string,
        ITimeSlot
    ] => {
        const lectureTimeSlots = {
            ...timeSlot, lectures: timeSlot.lectures.filter((lecture: ILecture) => satisfiesSearch(getLectureSearchFields(lecture), search)) // Only keep lectures that match search
        };

        return [key, lectureTimeSlots];
    })
    .filter(
        ([, timeSlot]: [string, ITimeSlot]) => timeSlot.lectures.length > 0
    ); // Only keep timeslots that have lectures left in them

export const LecturesPage: React.FC = () => {
    const { timeSlots } = useContext(AppContext);
    const [search, setSearch] = useState("");

    const timeSlotsToDisplay = filterBySearch(timeSlots, search);

    const uniqueDays = getUniqueDays(timeSlots.map(([, timeSlot]: [string, ITimeSlot]) => timeSlot));

    const timeSlotsForDayList = uniqueDays.map((day: number) => (
        <div key={day}>
            <DayHeader day={day} />
            {
                timeSlotsToDisplay
                    .filter(
                        ([, timeSlot]: [string, ITimeSlot]) => isTimeSlotOnDate(timeSlot, new Date(day))
                    )
                    .map(([key, timeSlot]: [string, ITimeSlot]) => (
                        <div key={key} id={timeSlot.startTime}>
                            <TimeSlot
                                startTime={timeSlot.startTime}
                                endTime={timeSlot.endTime}
                                lectures={timeSlot.lectures}
                            />
                        </div>

                    ))
            }
        </div>
    ));

    const pageContent = (
        <>
            <div className="search">
                <LectureSearchBar onSearchBarChange={setSearch} />
            </div>

            {timeSlotsForDayList}
        </>
    );

    return (
        <RefreshingPage
            pageContent={pageContent}
            itemCount={timeSlots.length}
            contentType={ContentType.Lectures}
        />
    );
};
