import ApolloClient from "apollo-boost";

import { getBouvetEventApiUrl } from "../config/environment";
import { getToken } from "../config/adal.config";

export { default as conferenceService } from "./conferenceService";
export { default as notificationService } from "./notificationService";
export { default as backendService } from "./backendService";

export const bouvetEventClient = new ApolloClient({
    uri: getBouvetEventApiUrl(),
    headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json"
    }
});
