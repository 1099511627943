import React, { useState } from "react";
import { IonCardContent } from "@ionic/react";
import ResizeDetector from "react-resize-detector";

interface IProps {
    children: React.ReactNode;
    expanded: boolean;
    onResized: (cardHeight: number) => void;
    isSpeakerContent?: boolean;
}

export const ExpandableCardContent: React.FC<IProps> = ({ children, expanded, onResized, isSpeakerContent }: IProps) => {
    const [height, setHeight] = useState(0);

    const onResize = (_: number, newHeight: number): void => {

        // +32 is used to add padding-bottom to speakerCards when they are displaying expandable content
        const height = isSpeakerContent ? newHeight + 32 : newHeight;

        setHeight(height);
        onResized(height);
    };//

    const style = {
        overflow: "hidden",
        height: expanded ? `${height}px` : "0",
        paddingBottom: expanded ? undefined : "0",
        paddingTop: "0",
        transition: "0.5s ease"
    };

    return (
        <IonCardContent className={"expandable"} style={style}>
            <div>
                {children}
                <ResizeDetector handleHeight onResize={onResize} />
            </div>
        </IonCardContent>
    );
};
