import React from "react";
import { render } from "react-dom";
import { Plugins } from "@capacitor/core";
import { runWithAdal } from "react-adal";

import App from "./App";
import { authContext } from "./config/adal.config";

const { App: capacitorApp } = Plugins;
const DO_NOT_LOGIN = false;

capacitorApp.addListener("backButton", () => {
    if (window.location.pathname === "/hjem") {
        capacitorApp.exitApp();
    } else {
        window.history.back();
    }
});

const renderApp: () => void = () => render(
    <App />,
    document.querySelector("#root")
);

runWithAdal(authContext, renderApp, DO_NOT_LOGIN);
