import React from "react";

import { IonImg, IonText } from "@ionic/react";
import Penguin from "img/penguin.svg";

export const NoContent = (): JSX.Element => (
    <div key="empty" className="ion-padding ion-text-center" style={{ height: "100%" }}>
        <div style={{ height: "70%" }} className="parent center-content-vertically">
            <IonImg src={Penguin} alt="pingvin" style={{ height: "100%" }} />
        </div>
        {/* Applying center-content-vertically doesnt work here for some reason*/}
        <IonText style={{ height: "30%", display: "flex", justifyContent: "center", flexDirection: "column" }}>
            Beklager, jeg fant ikke dette :(
            <br /><br />
            Jeg trenger internett, en oppdatert app og at du er invitert til en konferanse på <a href="https://event.bouvet.no/">event.bouvet.no</a>
        </IonText>
    </div >
);
