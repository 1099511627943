import { format } from "date-fns";
import { nb } from "date-fns/locale";
import { ITimeSlot } from "store/types";

export const timeStringAsHHMM: (time: string) => string = (time: string) => time ? time.slice(11, 16) : "";

export const getDateStringWithDay = (date: Date): string => {
    const dateString = format(new Date(date), "EEEE d. MMMM", { locale: nb });

    return dateString.charAt(0).toUpperCase() + dateString.slice(1);
};

export const getDateStringWithDayAndTime = (date: Date): string => {
    const dateString = format(new Date(date), "EEEE d. MMMM HH:mm", { locale: nb });

    return dateString.charAt(0).toUpperCase() + dateString.slice(1);
};

const oneHourInMillis = 1000 * 60 * 60;

export const stringToConsistentDateForAndroidIos = (dateString: string): number => {
    // This is done due to an inconsistency in how android/ios treats dates and their timezone info
    const date = new Date(new Date(`${dateString.replace(" ", "T")}Z`).getTime()).setMinutes(new Date(new Date(`${dateString.replace(" ", "T")}Z`).getTime()).getMinutes() + new Date(new Date(`${dateString.replace(" ", "T")}Z`).getTime()).getTimezoneOffset());

    return date + oneHourInMillis;
};

export const getUniqueDays = (timeSlots: ITimeSlot[]): number[] => {
    const days = timeSlots.map((timeSlot: ITimeSlot) => {
        const date = new Date(timeSlot.startTime);
        const timeSlotDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());

        return timeSlotDate.valueOf();
    });

    return days.filter((v: number, i: number) => days.indexOf(v) === i);
};

export const isTimeSlotOnDate = (timeSlot: ITimeSlot, date: Date): boolean => {
    const timeSlotStart = new Date(timeSlot.startTime);

    const isSameDay = timeSlotStart.getDate() === date.getDate();
    const isSameMonth = timeSlotStart.getMonth() === date.getMonth();
    const isSameYear = timeSlotStart.getFullYear() === date.getFullYear();

    return isSameDay && isSameMonth && isSameYear;
};

export default timeStringAsHHMM;
