import React, { useContext, useState } from "react";
import { IonCard, IonRow, IonCardHeader, IonCardTitle, IonButton, IonIcon, IonCardSubtitle, IonChip } from "@ionic/react";
import { heart, heartEmpty } from "ionicons/icons";
import { HashLink as Link } from "react-router-hash-link";

import { ILecture, ILectureSpeaker } from "../store/types";
import { AppContext } from "../store";
import { pinOutline, downOutline, upOutline } from "../icons";
import { Category, ExpandableCardContent } from "../components";

interface IProps extends ILecture {
    history: any;
    onHeightChanged: (heightDifference: number) => void;
    isParentAnimating: boolean;
}

const showSpeaker = (event: any): void => {
    event.stopPropagation();
};

export const LectureCard = ({ id, speakers, category, description, tags, name, location, onHeightChanged, isParentAnimating }: IProps): JSX.Element => {
    const context = useContext(AppContext);
    const [expansionHeight, setExpansionHeight] = useState(0);

    const [isOpen, setIsOpen] = useState(false);

    const handleFavoriteToggle = (event: any): void => {
        event.stopPropagation();
        context.setFavorite(id, !context.isFavorite(id));
    };

    const onExpandableCardHeightSet = (expansionHeight: number): void => {
        setExpansionHeight(expansionHeight);
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
        if (!isParentAnimating) {
            setIsOpen(!isOpen);

            if (expansionHeight !== 0) {
                onHeightChanged(!isOpen ? expansionHeight : -expansionHeight);
            }
        }

    };

    return (
        <IonCard
            onClick={handleClick}
            className="lecture-card card-background-color"
            id={`${id}`}
        >
            <IonRow className="card-space-between">
                <IonCardHeader className="ion-header">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <IonCardTitle className="semi-bold title ion-header">
                            {name}
                        </IonCardTitle>
                        <IonButton
                            fill="clear"
                            onClick={handleFavoriteToggle}
                            className="ion-button-icon"
                            mode="ios"
                        >
                            <IonIcon
                                className="favorite-icon"
                                slot="icon-only"
                                icon={context.isFavorite(id) ? heart : heartEmpty}
                            />
                        </IonButton>
                    </div>
                </IonCardHeader>
            </IonRow>
            <IonRow className="card-space-between card-padding">
                {category &&
                    <Category text={category}></Category>
                }
                <IonChip
                    className="ion-padding-top location-chip"
                    color="white"
                    mode="ios"
                >
                    <IonIcon icon={pinOutline} />
                    <p className="subtitle">{location}</p>
                </IonChip>
            </IonRow>
            <ExpandableCardContent expanded={isOpen} onResized={onExpandableCardHeightSet}>
                <span className="seperator" />
                {description}
                <br />
                <br />
                <IonCardSubtitle
                    className="subtitle no-transform"
                    style={{ paddingBottom: 6 }}
                    onClick={showSpeaker}
                >
                    {speakers.map(({ name, id }: ILectureSpeaker) => (
                        <Link smooth to={`/folk/#${name}`} key={id}>
                            <div style={{ display: "inline-flex", paddingRight: 5 }} key={name}>{name}</div>
                        </Link>
                    ))}
                </IonCardSubtitle>
                <IonRow style={{ paddingBottom: 12 }}>
                    {tags.map((tag: string) => (
                        <span key={tag} className="tag">
                            {tag}
                        </span>
                    ))}
                </IonRow>
            </ExpandableCardContent>
            <div className="bottom-arrow">
                <img
                    alt="bottom arrow"
                    src={isOpen ? upOutline : downOutline}
                    style={{
                        height: "100%",
                        width: "100%",
                        display: "block"
                    }}
                />
            </div>
        </IonCard>
    );
};
