import { IonCard, IonCardHeader, IonIcon, IonRow } from "@ionic/react";
import React from "react";

import { HashLink as Link } from "react-router-hash-link";
import { add } from "ionicons/icons";

export const FindLecture = ({ startTime }: { startTime: string; }): JSX.Element => (
    <IonCard className="lecture-card card-background-color find-lecture-card">
        <Link smooth to={`/foredrag/#${startTime}`}>
            <IonRow className="card-space-between">
                <IonCardHeader className="ion-header speaker-header" mode="md" style={{ alignItems: "center", justifyContent: "center", display: "inline-flex", textAlign: "center", margin: "0px auto" }}>
                    <IonIcon icon={add} size="large" />
                    <span className="semi-bold ion-no-margin" style={{ color: "#666", fontSize: "1.2em", marginLeft: 8 }} >
                        Velg foredrag
                    </span>
                </IonCardHeader>
            </IonRow>
        </Link>
    </IonCard>
);
