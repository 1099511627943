/* eslint-disable space-before-function-paren */
import React, { Component, createContext, ReactElement } from "react";
import { AppState, Plugins } from "@capacitor/core";

import { conferenceService, notificationService, backendService } from "../services";
import { ISpeaker, IEvent, ITimeSlot, IRawData, INotification, IFavoritesData, ErrorMessage } from "./types";
import { parseConferenceJson, getDefaultConferenceId } from "./utils";

const { App } = Plugins;

interface IProps {
    children: React.ReactNode;
}

interface IState {
    conferences: IEvent[];
    description: string;
    fetchData: (isRefreshing: boolean) => Promise<void>;
    error: string;
    favorites: IFavoritesData;
    fetchedConferences: Map<string, IEvent>;
    isFavorite: (id: number) => boolean;
    isLoading: boolean;
    failedToLoadEventData: boolean;
    failedToLoadNotifications: boolean;
    failedToLoadJson: boolean;
    name: string;
    notificationAlert: boolean;
    notificationCount: number;
    notifications: INotification[];
    selectedConferenceId: string;
    setFavorite: (id: number, value: boolean) => void;
    setNotificationAlert: (alert: boolean) => void;
    setSelectedConferenceId: (id: string) => void;
    setError: (error: string) => void;
    speakers: [string, ISpeaker][];
    timeSlots: Array<[string, ITimeSlot]>;
}

const initialState: IState = {
    conferences: [],
    description: "",
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchData: (isRefreshing: boolean) => new Promise<void>((resolve: () => void, reject: () => void) => {
        resolve();
    }),
    error: "",
    favorites: { lectureIds: [] },
    fetchedConferences: new Map<string, IEvent>(),
    isFavorite: () => false,
    isLoading: true,
    failedToLoadEventData: false,
    failedToLoadNotifications: false,
    failedToLoadJson: false,
    name: "",
    notificationAlert: false,
    notificationCount: 0,
    notifications: [],
    selectedConferenceId: "",
    setFavorite: () => ({}),
    setNotificationAlert: () => ({}),
    setSelectedConferenceId: () => ({}),
    setError: () => ({}),
    speakers: [],
    timeSlots: []
};

export default class StateProvider extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            ...initialState,
            fetchData: this.fetchData.bind(this),
            isFavorite: this.isFavorite.bind(this),
            setFavorite: this.setFavorite.bind(this),
            setNotificationAlert: this.setNotificationAlert.bind(this),
            setSelectedConferenceId: this.loadConferenceData.bind(this),
            setError: this.setError.bind(this)
        };
    }

    private async setConferenceData(data: IRawData, notifications: INotification[]): Promise<void> {
        const { speakers, timeSlots } = parseConferenceJson(data);
        const speakerIds = speakers.map(([, speaker]: [string, ISpeaker]) => speaker.id);
        let images = new Map<string, string>();
        let divisions = new Map<string, string>();

        try {
            images = await conferenceService.getSpeakerImages(speakerIds);
        } catch (error) {
            console.error("Could not get images:", error);
        }

        try {
            divisions = await conferenceService.resolveUnitName(speakerIds);
        } catch (error) {
            console.error("Could not get divisions:", error);
        }

        speakers.forEach((speaker: [string, ISpeaker]) => {
            const [, { id }] = speaker;

            speaker[1].image = images.get(id) || "";
            speaker[1].division = divisions.get(id) || "";
        });

        this.setState({
            speakers,
            timeSlots,
            notifications,
            isLoading: false
        });
    }

    public componentDidMount(): void {
        App.addListener("appStateChange", (state: AppState) => {
            if (state.isActive) {
                this.fetchData(false);
            }
        });

        notificationService.initNotifications();

        this.setState({ isLoading: !!this.state.timeSlots }, () => {
            this.fetchData(false);
        });
    }

    public async fetchData(isRefreshing: boolean): Promise<void> {
        try {
            const conferences = await conferenceService.getConferences();
            const { selectedConferenceId: stateId } = this.state;
            const selectedConferenceId = stateId ? stateId : await getDefaultConferenceId(conferences);

            this.setState({ conferences });

            if (selectedConferenceId) {
                await this.loadConferenceData(selectedConferenceId);
            } else {
                throw new Error("SelectedConferenceId was null");
            }
        } catch (error) {
            if (isRefreshing) {
                this.setError(ErrorMessage.Refresh);
            }

            this.setState({ failedToLoadEventData: true, failedToLoadJson: true, failedToLoadNotifications: true });
        }

        this.setState({ isLoading: false });
    }

    public onBackendRequestsDone(isNotificationError: boolean, isFavoriteError: boolean, isJsonError: boolean): void {
        if (isNotificationError && isFavoriteError && isJsonError) {
            this.setError(ErrorMessage.BackendDown);
        } else if (isJsonError) {
            this.setError(ErrorMessage.Json);
        } else if (isNotificationError) {
            this.setError(ErrorMessage.Notifications);
        } else if (isFavoriteError) {
            this.setError(ErrorMessage.Favorites);
        }

        this.setState({ failedToLoadJson: isJsonError, failedToLoadNotifications: isNotificationError });
    }

    public async loadConferenceData(id: string): Promise<void> {
        let notifications: INotification[];
        let favorites: IFavoritesData;
        let rawData: IRawData;


        let isNotificationError = false;
        let isFavoriteError = false;
        let isJsonError = false;

        try {
            notifications = await backendService.getUserNotifications(this.state.conferences);
        } catch (error) {
            notifications = [];
            isNotificationError = true;
        }

        try {
            favorites = await backendService.getUserFavorites(id);
        } catch {
            favorites = { lectureIds: [] };
            isFavoriteError = true;
        }

        try {
            rawData = await backendService.getConferenceJson(id);
        } catch {
            isJsonError = true;
        }

        this.onBackendRequestsDone(isNotificationError, isFavoriteError, isJsonError);

        this.setState({ selectedConferenceId: id }, async () => {
            let conference = null;

            try {
                conference = await conferenceService.getConferenceByEventId(id);
            } catch (error) {
                this.setError(ErrorMessage.ConferenceNotFound);
            }

            if (conference) {
                const { description, eventName } = conference;
                const fetchedConferences = this.state.fetchedConferences.set(id, conference);

                this.setState({
                    favorites,
                    description,
                    fetchedConferences,
                    name: eventName
                });

                if (!isJsonError) {
                    this.setConferenceData(rawData, notifications);
                }

                conferenceService.setLastConferenceId(id);
            }

            this.setState({ failedToLoadEventData: conference === null });

        });
    }

    public isFavorite(id: number): boolean {
        return this.state.favorites.lectureIds.includes(id.toString());
    }

    public setNotificationAlert(alert: boolean): void {
        this.setState({ notificationAlert: alert });
    }

    public setError(error: string): void {
        this.setState({ error });
    }

    public async setFavorite(id: number, value: boolean): Promise<void> {
        try {
            const { favorites } = this.state;

            if (value) {
                await backendService.addFavorite(this.state.selectedConferenceId, id.toString());
                favorites.lectureIds.push(id.toString());
            } else {
                const index: number = favorites.lectureIds.indexOf(id.toString());

                await backendService.deleteFavorite(this.state.selectedConferenceId, id.toString());
                favorites.lectureIds.splice(index, 1);
            }

            this.setState({ favorites });
        } catch (error) {
            this.setError(error.message);
        }
    }

    public render(): ReactElement {
        return (
            <AppContext.Provider
                value={{ ...this.state }}
            >
                {this.props.children}
            </AppContext.Provider>
        );
    }
}

export const AppContext = createContext<IState>({ ...initialState });
