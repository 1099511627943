import React, { memo } from "react";

import { IonRow, IonCardHeader, IonThumbnail, IonCardTitle, IonCardSubtitle } from "@ionic/react";
import { ISpeaker } from "../../store/types";
import { upOutline, downOutline } from "../../icons";
import defaultImage from "../../img/gravatar.svg";
import { ExpandableCardContent } from "../../components";
import SpeakerLectures from "./SpeakerLectures";

interface IProps extends ISpeaker {
    expandContent: boolean;
}

const onSpeakerCardResized = (expansionHeight: number): void => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const a = "a";
};

const SpeakerCard = memo((speaker: IProps): JSX.Element => {
    const showContent = speaker.expandContent;
    const cardImage = speaker.image ? `data:image/png;base64, ${speaker.image}` : defaultImage;

    return (
        <>
            <IonRow className="card-space-between">
                <IonCardHeader className="ion-header speaker-header" mode="md">
                    <IonThumbnail>
                        <img
                            alt={speaker.name}
                            src={cardImage}
                        />
                    </IonThumbnail>
                    <IonCardTitle
                        className="semi-bold title ion-header ion-no-margin"
                        mode="md"
                    >
                        {speaker.name}
                        <IonCardSubtitle mode="md">
                            {speaker.division}
                        </IonCardSubtitle>
                    </IonCardTitle>
                </IonCardHeader>
            </IonRow>
            <ExpandableCardContent expanded={showContent} onResized={onSpeakerCardResized} isSpeakerContent>
                <div className="speaker-description">{speaker.description}</div>
                <SpeakerLectures lectures={speaker.lectures} />
            </ExpandableCardContent>
            <div className="bottom-arrow">
                <img
                    alt="expand"
                    src={showContent ? upOutline : downOutline}
                    style={{
                        height: "100%",
                        width: "100%",
                        display: "block"
                    }}
                />
            </div>
        </>
    );
});

export default SpeakerCard;
