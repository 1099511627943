import { home, calendar, people, heart } from "ionicons/icons";

import { HomePage } from "./HomePage";
import { LecturesPage } from "./LecturesPage";
import { SpeakersPage } from "./SpeakersPage";
import { FavoritesPage } from "./FavoritesPage";
import { NotificationsPage } from "./NotificationsPage";
import { IPage } from "store/types";

export const pages: IPage[] = [
    {
        tab: "hjem",
        title: "Konferanse",
        icon: home,
        component: HomePage
    },
    {
        tab: "foredrag",
        title: "Foredrag",
        icon: calendar,
        component: LecturesPage
    },
    {
        tab: "folk",
        title: "Folk",
        icon: people,
        component: SpeakersPage
    },
    {
        tab: "agenda",
        title: "Min Agenda",
        icon: heart,
        component: FavoritesPage
    },
    {
        tab: "varslinger",
        title: "Varslinger",
        component: NotificationsPage
    }
];
