import React, { useContext } from "react";
import { IonHeader, IonTitle, IonToolbar, IonPage, IonIcon, IonButton, IonContent } from "@ionic/react";
import { useLocation } from "react-router";
import { AppContext } from "../store";
import { notificationBellActive, notificationAlertBell, notificationBell } from "../icons";
import { ConferencePicker } from "./ConferencePicker";

interface IProps {
    title: string;
    PageComponent: React.FC<{}>;
}

interface ISelectChangeEventDetail {
    value: any | any[] | undefined | null;
}

export const Page: React.FC<IProps> = ({ title, PageComponent }: IProps) => {
    const context = useContext(AppContext);
    const { pathname } = useLocation();

    const notificationPageActive = pathname === "/varslinger";
    const buttonHref = notificationPageActive ? undefined : "/varslinger";

    const notificationIcon = (): string => {
        if (notificationPageActive) {
            return notificationBellActive;
        }

        return context.notificationAlert ? notificationAlertBell : notificationBell;
    };

    const getTitle = (): JSX.Element => {

        if (title !== "Konferanse" || !context.conferences.length) {
            return <IonTitle slot="start">{title}</IonTitle>;
        }

        return <ConferencePicker />;

    };

    return (
        <IonPage id="main">
            <IonHeader>
                <IonToolbar mode="md" className="nav-bar" >
                    {getTitle()}
                    <IonButton slot="end" fill="clear" mode="ios" routerLink={buttonHref}>
                        <IonIcon icon={notificationIcon()} size="medium" />
                    </IonButton>
                </IonToolbar>
            </IonHeader>
            <IonContent className="content-background-color">
                <PageComponent />
            </IonContent>
        </IonPage>
    );
};
