import React from "react";

interface IProps {
    text: string;
}

export const Category = ({ text }: IProps): JSX.Element => {

    const colorClassNames = [
        "category-color-1",
        "category-color-2",
        "category-color-3",
        "category-color-4",
        "category-color-5",
        "category-color-6",
        "category-color-7"
    ];

    const getColorClassName = (text: string): string => {
        // Generate a number based on the characters in the string
        const index = text.split("").reduce((sum: number, current: string) => sum + current.charCodeAt(0), 0);

        return colorClassNames[index % colorClassNames.length];
    };

    return (
        <span key={text} className={`category ${getColorClassName(text)}`}>
            {text}
        </span>
    );
};
