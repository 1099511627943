/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { IonGrid, IonCol, IonIcon } from "@ionic/react";
import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router";
import { arrowDropdown, arrowDropup } from "ionicons/icons";
import { timeStringAsHHMM } from "../utility/dateLogic";
import { ILecture } from "store/types";
import { LectureCard } from "components/LectureCard";
import { FindLecture } from "components/FindLecture";

interface IProps {
    startTime: string;
    endTime: string;
    lectures: ILecture[];
}

interface IStyle {
    transition: string;
    overflow: string;
    height: number | undefined;
}

const tryGetHeight = (reference: any): (number | undefined) => {
    try {
        return reference.current.clientHeight;
    } catch (error) {
        return undefined;
    }

};

export const TimeSlot: React.FC<IProps> = ({ startTime, endTime, lectures }: IProps) => {
    const endTimeDate = new Date(endTime);
    const [height, setHeight] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isAnimating, setIsAnimating] = useState(true);
    const [expanded, setExpanded] = useState(new Date() < endTimeDate);

    const reference: any = useRef(null);
    const location = useLocation();

    const leftColumn: JSX.Element[] = [];
    const rightColumn: JSX.Element[] = [];

    const heightOfFavoriteCardsThatIsRenderedBeforeFullHeightIsAchived = 30;

    const minimizeTimeSlotIfJustFinished = (): void => {
        const now = new Date();

        const isSameMinute = now.getMinutes() === endTimeDate.getMinutes();
        const isSameHour = now.getHours() === endTimeDate.getHours();
        const isSameDay = now.getDate() === endTimeDate.getDate();
        const isSameMonth = now.getMonth() === endTimeDate.getMonth();
        const isSameYear = now.getFullYear() === endTimeDate.getFullYear();

        if (isSameMinute && isSameHour && isSameDay && isSameMonth && isSameYear) {
            setExpanded(false);
        }

    };

    useEffect(() => {
        setTimeout(() => {
            setIsAnimating(false);

            const currentHeight = tryGetHeight(reference);

            if (currentHeight && height === 0 && currentHeight !== heightOfFavoriteCardsThatIsRenderedBeforeFullHeightIsAchived) {
                setHeight(currentHeight);
            }

            setIsLoaded(true);

        }, 500);

        const interval = setInterval(() => minimizeTimeSlotIfJustFinished(), 1000 * 30);

        return (): void => {
            clearInterval(interval);
        };

    });

    useEffect(() => {
        setTimeout(() => {
            const currentHeight = tryGetHeight(reference);

            if (currentHeight && height !== 0 && height !== currentHeight && currentHeight !== heightOfFavoriteCardsThatIsRenderedBeforeFullHeightIsAchived) {
                setHeight(currentHeight);
            }
        }, 500);
    }, [height, lectures]);

    const baseStyle: IStyle = {
        transition: "all 0.5s ease 0s",
        overflow: "hidden",
        height: undefined
    };

    const getStyle = (expanded: boolean, isLoaded: boolean, height: number): IStyle => {
        if (expanded && isLoaded && height !== 30) {
            baseStyle.height = height;
        } else if (!expanded && isLoaded) {
            baseStyle.height = 0;
        } else {
            baseStyle.height = 5; // comment out if you want to disable initial animation
        }

        return baseStyle;
    };

    const startAnimation = (): void => {
        setIsAnimating(true);

        setTimeout(() => {
            setIsAnimating(false);
        }, 525);
    };

    const onLectureCardHeightChanged = (heightChange: number): void => {
        if (!isAnimating) {
            setHeight(reference.current.clientHeight + heightChange);
            startAnimation();
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleDividerClick = (event: React.MouseEvent<HTMLDivElement>): void => {
        if (!isAnimating) {
            if (expanded) {
                setHeight(reference.current.clientHeight);
            }

            setExpanded(!expanded);
            startAnimation();
        }

    };
    const getLectureCards = (): JSX.Element => {
        if (lectures.length) {
            const lectureCards = lectures.map((lecture: ILecture) => (
                <LectureCard
                    isParentAnimating={isAnimating}
                    onHeightChanged={onLectureCardHeightChanged}
                    key={lecture.id}
                    {...lecture}
                    history={location}
                />
            ));
            let leftHeight = 0;
            let rightHeight = 0;

            lectureCards.forEach((element: JSX.Element) => {
                const elementHeight = 1;

                if (leftHeight <= rightHeight) {
                    leftColumn.push(element);
                    leftHeight += elementHeight;
                } else {
                    rightColumn.push(element);
                    rightHeight += elementHeight;
                }
            });

            return (
                <>
                    <IonCol>{leftColumn}</IonCol>
                    <IonCol>{rightColumn}</IonCol>
                </>
            );
        } else {
            return (
                <IonCol>
                    <FindLecture startTime={startTime} />
                </IonCol>
            );
        }

    };

    const timeSlotText = `${timeStringAsHHMM(startTime)} - ${timeStringAsHHMM(endTime)}`;

    const timeSlotHeader = (
        <div style={{ margin: "0px 12px" }} onClick={handleDividerClick}>
            <h4 className="timeSlotHeader" style={{ margin: 0 }}>
                <span style={{ color: "grey" }} >
                    {timeSlotText}
                    <IonIcon
                        size="large"
                        color="#262626"
                        mode="md"
                        icon={expanded ? arrowDropup : arrowDropdown}
                    />
                </span>
            </h4>
        </div >
    );

    return (
        <>

            {timeSlotHeader}
            <div style={getStyle(expanded, isLoaded, height)} >
                <IonGrid ref={reference}>
                    {getLectureCards()}
                </IonGrid>

            </div>
        </>
    );
};
