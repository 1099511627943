import { Plugins } from "@capacitor/core";
import { AuthenticationContext, adalFetch, withAdalLogin } from "react-adal";
import { isApp } from "./environment";

const { App, Browser } = Plugins;

// Listener for redirecting authentication requests
App.addListener("appUrlOpen", (data: any) => {
    const { url } = data;
    const [, hash] = url.split("#", 2);

    Browser.close();

    const redirect = `/signin-oidc#${hash}`;

    window.location.assign(redirect);
});

const redirectUri = isApp
    ? "https://bouvetconfront.z6.web.core.windows.net/signin-android.html"
    : `${window.location.origin}/signin-oidc`;

export const adalConfig = {
    cacheLocation: "localStorage" as "localStorage",
    clientId: "2784b168-65b6-40b3-bf66-4ca2449203bb",
    displayCall: (url: string): Promise<void> => Browser.open({ url, windowName: "_self" }),
    endpoints: {
        graphApiUri: "https://graph.microsoft.com"
    },
    postLogoutRedirectUri: window.location.origin,
    redirectUri,
    tenant: "bouvetasa.onmicrosoft.com"
};

export const authContext = new AuthenticationContext(adalConfig);

export const getToken = (): any => authContext.getCachedToken(authContext.config.clientId);

export const adalApiFetch = (
    fetch: (input: string, init: any) => Promise<any>,
    url: string,
    options: any
): any => adalFetch(
    authContext,
    adalConfig.endpoints.graphApiUri,
    fetch,
    url,
    options
);

export const withAdalLoginApi = withAdalLogin(
    authContext,
    adalConfig.endpoints.graphApiUri
);
