import React, { useContext, useState, useEffect, useCallback } from "react";
import {
    IonCard
} from "@ionic/react";

import { useLocation } from "react-router";

import SpeakerCard from "./SpeakerCard";
import { LectureSearchBar } from "components/LectureSearchBar";
import { ISpeaker, ContentType } from "store/types";
import { AppContext } from "store";
import RefreshingPage from "pages/RefreshingPage";
import { satisfiesSearch, getSpeakerSearchFields } from "utility/searchLogic";

interface ISpeakerProps extends ISpeaker {
    key: string;
    expandContent: boolean;
}

export const SpeakersPage: React.FC = () => {

    const context = useContext(AppContext);
    const [search, setSearch] = useState("");
    const { speakers } = context;
    const location = useLocation();
    const selectedSpeaker = decodeURI(location.hash.replace("#", ""));
    const [speakersProps, setSpeakersProps] = useState(
        speakers.map(([key, speaker]: [string, ISpeaker]) => ({
            key,
            ...speaker,
            expandContent: selectedSpeaker === speaker.name
        }))
    );

    useEffect(() => {
        setSpeakersProps(speakers.map(([key, speaker]: [string, ISpeaker]) => ({
            key,
            ...speaker,
            expandContent: selectedSpeaker === speaker.name
        })));
    }, [speakers, selectedSpeaker]);

    useEffect(() => {
        const selectedSpeaker = decodeURI(location.hash.replace("#", ""));

        setSpeakersProps((prevProps: any) => prevProps.map((speaker: ISpeakerProps) => ({
            ...speaker,
            expandContent: selectedSpeaker === speaker.name
        })));
    }, [location]);

    const expandCard = useCallback((id: string) => (
        setSpeakersProps((prevProps: any) => prevProps.map((speaker: ISpeakerProps) => ({
            ...speaker,
            expandContent: speaker.id === id ? !speaker.expandContent : speaker.expandContent
        })))
    ), [setSpeakersProps]);

    // Only speakers that satisfy the search shall be displayed
    const speakersToDisplay = speakersProps.filter((speaker: ISpeakerProps) => satisfiesSearch(getSpeakerSearchFields(speaker), search));

    const speakerCards = speakersToDisplay.map((speaker: ISpeakerProps) => (
        <IonCard
            className="speaker-card card-background-color"
            onClick={(): void => expandCard(speaker.id)}
            key={speaker.key}
            id={speaker.name}
        >
            <SpeakerCard {...speaker} />
        </IonCard>
    ));

    const pageContent = (
        <>
            <LectureSearchBar onSearchBarChange={setSearch} />
            <div className="card-list">
                {speakerCards}
            </div>
        </>
    );

    return (
        <RefreshingPage
            pageContent={pageContent}
            itemCount={speakers.length}
            contentType={ContentType.Speakers}
        />
    );
};

