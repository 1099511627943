import { gql } from "apollo-boost";

export const GET_CONFERENCES = gql`
    query invitedEvents {
        invitedEvents {
            id
            eventName
            startDate
            endDate
            description
            location
            modules {
                id
            }
        }
    }
`;

export const GET_CONFERENCE = gql`
    query GetEventById($eventId: String!) {
        event(eventId: $eventId) {
            id
            eventName
            description
            __typename
        }
    }
`;

export const GET_USER_PHOTOS = gql`
    query GetUserPhotos($users: [String]!) {
        photos(users: $users) {
            id
            photo
        }
    }
`;

export const RESOLVE_UNIT_NAME = gql`
    query resolveUnitName($userId: [String]!) {
        resolveUnitNameList(userIds: $userId) {
            userId
            unit
        }
    }
`;

export const GET_MY_PARTICIPANT = gql`
    query GetMyParticipation($eventId: String!) {
        participant(eventId: $eventId) {
            modules {
                id
                data
            }
        }
    }
`;

export const CREATE_PARTICIPANT = gql`
    mutation createParticipant($participant: ParticipantInput!) {
        createParticipant(participant: $participant) {
            eventId
            comment
            acceptanceStatus
            acceptanceStatusEnum
            showAllergies
            numberOfGuests
            userId
            __typename
        }
    }
`;

export const CREATE_MODULE_PARTICIPANT = gql`
    mutation createModuleParticipant($moduleParticipant: ModuleParticipantInput!){
        createModuleParticipant(moduleParticipant: $moduleParticipant){
            eventId
            data
            id
        }
    }
`;
