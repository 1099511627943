import { IonButton, IonContent, IonHeader, IonLabel, IonList, IonModal, IonTitle, IonToolbar, IonIcon } from "@ionic/react";
import React, { useContext, useState } from "react";
import { arrowDropdown } from "ionicons/icons";

import { AppContext } from "../store";
import { IEvent } from "../store/types";
import ConferenceCard from "./ConferenceCard";

interface IProps {
    isChangeConferenceOpen: boolean;
    selectedConferenceId: string;
}

enum EventType {
    archived = 0,
    coming = 1
}

export const ConferencePicker: React.FC = () => {
    const context = useContext(AppContext);
    const [showModal, setShowModal] = useState(false);
    const [selectedConference, setSelectedConference] = useState("Konferanse");
    const { conferences } = context;

    const conferenceCards = conferences.map(({ id, description, eventName, startDate, location }: IEvent) => {
        if (id === context.selectedConferenceId && eventName !== selectedConference) {
            setSelectedConference(eventName);
        }

        return (
            <ConferenceCard
                key={id}
                description={description}
                isSelected={
                    id === context.selectedConferenceId
                }
                id={id}
                conferenceName={eventName}
                location={location}
                startTimeString={startDate}
            />
        );

    });

    const getConferenceCards = (eventType: EventType): JSX.Element => {
        if (eventType === EventType.archived) {
            return <IonLabel>Støtte for arkiverte events kommer.</IonLabel>;
        }

        return (
            <>
                <IonList>
                    {conferenceCards}
                </IonList>
            </>
        );
    };

    return (
        <>
            <IonContent >
                <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)} >
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>Velg konferanse</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent style={{ background: "#f8f8f8", height: "100%" }}>
                        {getConferenceCards(EventType.coming)}
                    </IonContent>

                    <IonButton
                        color="medium"
                        style={{ margin: "12px auto", display: "table" }}
                        onClick={(): void => setShowModal(false)}
                    >
                        Lukk
                    </IonButton>

                </IonModal>

            </IonContent>

            <IonTitle
                onClick={(): void => setShowModal(true)}
                style={{ marginTop: 5 }}
                slot="start"
            >
                Velg konferanse
                <IonIcon
                    size="large"
                    mode="md"
                    icon={arrowDropdown}
                    style={{ verticalAlign: "middle" }}
                />
            </IonTitle>
        </>
    );
};
