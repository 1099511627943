export interface IPage {
    component: React.FC;
    icon?: {
        ios: string;
        md: string;
    };
    tab: string;
    title: string;
}

export interface ISpeakerLecture {
    category: string;
    description: string;
    endTime: string;
    id: number;
    location: string;
    name: string;
    startTime: string;
    tags: string[];
}

export interface ISpeaker {
    id: string;
    description: string;
    division?: string;
    image?: string;
    lectures: ISpeakerLecture[];
    name: string;
}

export interface IEvent {
    description: string;
    endDate: string;
    eventName: string;
    id: string;
    location: string;
    modules: IEventModule[];
    startDate: string;
}

export interface IRoom {
    id: number;
    name: string;
    floor: string;
    capacity: number;
}

export interface ITimeSlot {
    endTime: string;
    lectures: ILecture[];
    startTime: string;
}

export interface IRawTimeSlot {
    id: number;
    room: IRoom;
    startTime: string;
    endTime: string;
}

export interface ILecture {
    category: string;
    description: string;
    speakers: ILectureSpeaker[];
    location: string;
    name: string;
    id: number;
    tags: string[];
}

export interface IRawLecture {
    name: string;
    description: string;
    lecturers: ILectureSpeaker[];
    category: string;
    tags: string[];
    timeSlots: IRawTimeSlot[];
}

export interface ITimeSlotLecture {
    category: string;
    description: string;
    id?: number;
    speakers: ILectureSpeaker[];
    location: string;
    name: string;
    tags: string[];
}

export interface ILectureSpeaker {
    id: string;
    name: string;
    description: string;
    division: string;
}

export interface IEventModule {
    id: string;
    eventId: string;
    data: IRawData;
}

export interface IRawData {
    lectures: IRawLecture[];
    rooms: IRoom[];
    roomCount: number;
}

export interface INotifications {
    id: number;
    text: string;
    date: string;
}

export interface INotification {
    id: number;
    title: string;
    body: string;
    conferenceId: string;
    mode: number;
    recipients: string[];
    scheduledTime: string;
    sound: string;
    status: number;
    conferenceName?: string;
}

export interface IFavoritesData {
    lectureIds: string[];
}

export enum ErrorMessage {
    Refresh = "Kunne ikke hente ny data.",
    Json = "Kunne ikke hente konferanseprogrammet.",
    Notifications = "Kunne ikke hente varslene dine.",
    Favorites = "Kunne ikke hente favorittene dine.",
    BackendDown = "Kunne ikke hente data.",
    SaveFavorite = "Kunne ikke lagre favoritten din.",
    UpdateFavorite = "Kunne ikke oppdatere favoritten din.",
    DeleteFavorite = "Kunne ikke slette favoritten din.",
    ConferenceNotFound = "Fant ikke data for konferansen."
}

export enum ContentType {
    Agenda = "agenda",
    Lectures = "foredrag",
    Speakers = "foredragsholdere",
    Notifications = "varslinger",
    Conferences = "konferanser"
}
