import React from "react";

import { IonImg, IonText } from "@ionic/react";
import Sun from "img/sun.svg";
import BeachPenguinNoSun from "img/beachPenguinNosun.svg";
import "./MissingContent.css";
import { ContentType } from "store/types";

interface IProps {
    contentType: ContentType;
}

export const MissingContent = ({ contentType }: IProps): JSX.Element => {
    const rotationSpeed = 10;

    const getMessage = (): string => {

        if (contentType === ContentType.Notifications) {
            return "Det ser ikke ut som at du har fått noen varslinger ennå...";

        } else {
            return `Det ser ikke ut som at eventansvarlig har lagt inn noen ${contentType} ennå...`;
        }
    };

    return (
        <div key="empty" className="ion-padding ion-text-center" style={{ height: "100%" }}>
            <div style={{ height: "80%", verticalAlign: "center" }} className="parent">
                <IonImg src={BeachPenguinNoSun} className="background-image" alt="pingvin" style={{ animation: `rotation ${rotationSpeed}s infinite linear` }} />
                <IonImg src={Sun} alt="pingvin" className="overlay-image" />
            </div>
            <div style={{ height: "20%", display: "flex", justifyContent: "center", flexDirection: "column" }}>
                <IonText>
                    {getMessage()}
                </IonText>
            </div>

        </div >

    );
};
